import Parse from 'parse'
import React, { Suspense } from 'react'
import { Navigate, createBrowserRouter, defer, redirect, useLocation } from 'react-router-dom'
import {
  user as userTools,
  region as regionFunctions,
  payout,
  ITEM_STATUS,
  marketplace
} from '@sellpy/commons'
import isMobile from 'ismobilejs'
import config from 'config'
import { initializeI18n } from 'init-i18n'
import { captureException, wrapCreateBrowserRouter } from '@sentry/react'
import {
  FEATURE_SELL,
  FEATURE_P2P,
  FEATURE_TYPES_PAGE,
  FEATURE_BAG_PICKUP,
  FEATURE_REFERRALS,
  FEATURE_HM_COLLAB_BUY,
  FEATURE_HM_COLLAB_SELL,
  FEATURE_STATEMENT,
  FEATURE_SELL_SIGNUP,
  FEATURE_CREDITS,
  FEATURE_FLATTERED_COLLAB_SELL,
  FEATURE_KLARNA_COLLAB_BUY,
  FEATURE_SNAPCHAT_COLLAB_BUY,
  FEATURE_FASHIONWEEK_COLLAB_BUY,
  isFeatureEnabled,
  initFeatureToggle
} from '../featureToggle'
import { FASHIONWEEK, FLATTERED, HM, KLARNA, SNAPCHAT } from '../../common/collaboration/config'
import { locale, localeBooleansForGQL } from '../../common/region/locale'
import { ensureLocalStorage } from '../lib/ensureLocalStorage.js'
import { initializeParse } from '../lib/parse.js'
import { setAbTestFraction } from '../../common/analytics/abTest.js'
import { initializeFacebookAsync } from '../lib/facebook'
import { tagManager } from '../lib/googleTagManager'
// import { initializeTrustbadge } from '../lib/trustbadge'
import { initializeMicroTrustScore } from '../lib/microTrustScore'
import { initializeTrustProfile } from '../lib/trustProfile.js'
import { externalReviewService, region, showTrustScore } from '../../common/region/region.js'
import App from '../app/App.jsx'
import { getCreateQueryPreloader } from '../apollo/apolloClientSingleton.js'
import { getOrderOverview } from '../order/order.gql'
import { getOrdersOverview } from '../orders/orders.gql'
import { newClaimPageQuery } from '../claims/newClaimPage.query.gql'
import { editClaimPageQuery } from '../claims/editClaimPage.query.gql'
import { withdraw } from '../withdraw/withdraw.gql'
import { automaticPayoutConfig } from '../withdraw/automaticPayoutBox.gql'
import { getSalesOverview } from '../sales/sales.gql'
import { getAllStores } from '../categories/stores/getAllStores.gql'
import { receivedSalesTabOverview } from '../sales/components/received/receivedSaleTab.gql'
import { activeSalesTabOverview } from '../sales/components/active/activeSaleTab.gql'
import { endedSalesOverview } from '../sales/components/ended/endedSaleTab.gql'
import getLatestItemRecommendationsForUser from '../myShopping/pages/weeklyRecommendations/weeklyRecommendations.gql'
import getItemsPreviouslyInCart from '../myShopping/pages/previouslyInCart/previouslyInCart.gql'
import getTrackedSearchesForUser from '../myShopping/pages/trackedSearches/trackedSearches.gql'
import { getBankAccount } from '../bankAccount/bankAccountVerificationComplete.gql'
import { getContainerPrice } from '../orderBag/containerOrder.gql'
import { getUnverifiedPayOutAndBankAccount } from '../payOuts/bank/payoutVerificationInfo.gql'
import { sellerPDPQuery } from '../item/seller/sellerPDP.gql'

import { landingPageLoader } from '../landing/loader.js'
import Setup from '../payOutSetup/Setup.jsx'
import BankAccountSetup from '../payOutSetup/BankAccount.jsx'
import CharityAccountSetup from '../payOutSetup/Charity.jsx'
import AutomaticPayoutConfirmation from '../payOutSetup/AutomaticPayoutConfirmation.jsx'
import getAllFavorites from '../components/favoriteButton/getAllFavorites.gql'
import getFavoritesInList from '../myShopping/pages/favorites/getFavoritesInList.gql'
import { salesControlLoader } from '../myItems/salesControl/loader'
import { getOldestDateForPreviouslyInCart } from '../myShopping/pages/previouslyInCart/utils'
import { FallbackLandingPageContent } from '../landing/FallbackLanding.jsx'
import { SearchSkeleton } from './skeletons/SearchSkeleton.jsx'
import { StoreSkeleton } from './skeletons/StoreSkeleton.jsx'
import BuyerPDPSkeleton from './skeletons/BuyerPDPV2Skeleton.jsx'
import { ErrorElement } from './ErrorElement.jsx'
import Layout from './Layout.jsx'

// IMPORTANT! Don't put "element" or "Component", but use the React.lazy() & import() pattern below.
// Otherwise they will be part of the main bundle which is required on every page.
const CollabLanding = React.lazy(() => import('../landing/CollabLanding.jsx'))
const NotFound = React.lazy(() => import('../components/NotFound.jsx'))
const EmptyCart = React.lazy(() => import('../emptyCart/EmptyCart.jsx'))
const OrderSearch = React.lazy(() => import('../myItems/order/OrderSearch.jsx'))
const CircleSearch = React.lazy(() => import('../myItems/circle/CircleSearch.jsx'))
const DeprecatedRoute = React.lazy(() => import('./deprecatedRoute/DeprecatedRoute.jsx'))
const AdyenAdvancePayment = React.lazy(() => import('../checkout/AdyenAdvancePayment.jsx'))
const CircleSellerPDP = React.lazy(() => import('../item/circle-seller/CircleSellerPDP.jsx'))
const SellerPDP = React.lazy(() => import('../item/seller/SellerPDP.jsx'))
const BuyerPDP = React.lazy(() => import('../item/buyer/BuyerPDP_V2.jsx'))
const PasswordReset = React.lazy(() => import('../passwordReset/Page.jsx'))
const CircleLanding = React.lazy(() => import('../p2p/CircleLanding.jsx'))
const PaymentConfirmation = React.lazy(() =>
  import('../paymentConfirmation/PaymentConfirmation.jsx')
)
const Licenses = React.lazy(() => import('../app/Licenses.jsx'))
const ContainerOrderHistory = React.lazy(() =>
  import('../containerOrderHistory/ContainerOrderHistory.jsx')
)
const ContainerOrder = React.lazy(() => import('../containerOrder/ContainerOrder.jsx'))
const OrderClaims = React.lazy(() => import('../claims/OrderClaims.jsx'))
const ClaimView = React.lazy(() => import('../p2p/ClaimView.jsx'))
const Home = React.lazy(() => import('../home/Home.jsx'))
const InstantSearch = React.lazy(() => import('../search/InstantSearch.jsx'))
const SellerLandingPage = React.lazy(() => import('../sellerLanding/SellerLandingPage.jsx'))
const FlatteredSellerLandingPage = React.lazy(() =>
  import('../sellerLanding/collab/flattered/FlatteredSellerLandingPage.jsx')
)
const HmSellerLandingPage = React.lazy(() =>
  import('../sellerLanding/collab/HmSellerLandingPage.jsx')
)
const Profile = React.lazy(() => import('../profile/Page.jsx'))
const ItemEdit = React.lazy(() => import('../itemEdit/ItemEdit.jsx'))
const ItemPricingCheckout = React.lazy(() => import('../itemPricing/ItemPricingCheckout.jsx'))
const Balance = React.lazy(() => import('../balance/Balance.jsx'))
const ReferralsPage = React.lazy(() => import('../referrals/Referrals.jsx'))
const Invited = React.lazy(() => import('../referrals/Invited.jsx'))
const CharityPayout = React.lazy(() => import('../payOuts/charity/CharityPayout.jsx'))
const CharityPayoutComplete = React.lazy(() =>
  import('../payOuts/charity/CharityPayoutComplete.jsx')
)
const BankPayoutComplete = React.lazy(() => import('../payOuts/bank/BankPayoutComplete.jsx'))
const BankPayout = React.lazy(() => import('../payOuts/bank/BankPayout.jsx'))

const ContainerPickup = React.lazy(() => import('../containerPickup/ContainerPickup.jsx'))
const CollabContainerPickup = React.lazy(() =>
  import('../collabContainerPickup/CollabContainerPickup.jsx')
)
const HowItWorks = React.lazy(() => import('../sellerLanding/HowItWorks.jsx'))
const PricingInfo = React.lazy(() => import('../sellerLanding/PricingInfo.jsx'))
const ContainerSubscriptionPage = React.lazy(() =>
  import('../containerSubscription/ContainerSubscriptionPage.jsx')
)
const BusinessLandingPage = React.lazy(() => import('../businessLanding/BusinessLandingPage.jsx'))
const PressLandingPage = React.lazy(() => import('../pressLanding/PressLandingPage.jsx'))
const PressImages = React.lazy(() => import('../pressLanding/PressImages.jsx'))
const PressReleasePage = React.lazy(() => import('../pressLanding/PressReleasePage.jsx'))
const WithinShippingCost = React.lazy(() => import('../withinShippingCost/Page.jsx'))
const CreateSellerReturn = React.lazy(() => import('../sellerReturn/Page.jsx'))
const PasswordPage = React.lazy(() => import('../profile/PasswordPage.jsx'))
const PurchaseGiftCardPage = React.lazy(() => import('../giftCard/PurchaseGiftCardPage.jsx'))
const RedeemPage = React.lazy(() => import('../giftCard/Redeem.jsx'))
const RedeemSucceededPage = React.lazy(() => import('../giftCard/RedeemSucceeded.jsx'))
const CreditPage = React.lazy(() => import('../credit/Credit.jsx'))
const BrandsPage = React.lazy(() => import('../categories/brands/BrandsPage.jsx'))
const TypesPage = React.lazy(() => import('../categories/types/TypesPage.jsx'))
const Sustainability = React.lazy(() => import('../sustainability/Sustainability.jsx'))
const UnsellableAction = React.lazy(() => import('../unsellableAction/UnsellableAction.jsx'))
const FulfillP2POrder = React.lazy(() => import('../p2p/FulfillP2POrder.jsx'))
const NotEnabledFeature = React.lazy(() => import('../featureToggle/NotEnabled.jsx'))
const Support = React.lazy(() => import('../support/Support.jsx'))
const About = React.lazy(() => import('../about/Landing.jsx'))
const StatementLanding = React.lazy(() => import('../statement/Landing.jsx'))
const FeatureToggle = React.lazy(() => import('../featureToggle/Page.jsx'))
const CustomerImpersonation = React.lazy(() =>
  import('../customerImpersonation/CustomerImpersonation.jsx')
)
const VerifyBankAccount = React.lazy(() => import('../bankAccount/VerifyBankAccount.jsx'))
const Store = React.lazy(() => import('../store/Store.jsx'))
const BuyerLanding = React.lazy(() => import('../myShopping/pages/landing/BuyerLanding.jsx'))
const SellSignup = React.lazy(() => import('../sellerLanding/SellerSignupPage.jsx'))
const HmResellReturn = React.lazy(() => import('../claims/hmResellReturns/HmResellReturn.jsx'))
const EmailSuppression = React.lazy(() => import('../profile/EmailSuppression.jsx'))
const Newsletter = React.lazy(() => import('../landing/Newsletter.jsx'))
const Upcycle = React.lazy(() => import('../upcycle/Upcycle.jsx'))
const LoyaltyProgramSignUp = React.lazy(() => import('../loyalty/LoyaltySignUp.jsx'))
const LoyaltyInfo = React.lazy(() => import('../loyalty/LoyaltyInfo.jsx'))
const CLIPSearch = React.lazy(() => import('../CLIPSearch/CLIPSearch.jsx'))
const CLIPSearchFromItems = React.lazy(() =>
  import('../CLIPSearch/CLIPImageFromItemsSearch/CLIPImageFromItemSearch.jsx')
)
const Cart = React.lazy(() => import('../checkoutV2/cart/Cart.jsx'))
const Checkout = React.lazy(() => import('../checkoutV2/checkout/Checkout.jsx'))
const ValidateEmail = React.lazy(() =>
  import('../checkoutV2/checkout/pages/validateEmail/ValidateEmail.jsx')
)
const Address = React.lazy(() => import('../checkoutV2/checkout/pages/address/Address.jsx'))
const Shipping = React.lazy(() => import('../checkoutV2/checkout/pages/shipping/Shipping.jsx'))
const Summary = React.lazy(() => import('../checkoutV2/checkout/pages/summary/Summary.jsx'))
const Payment = React.lazy(() => import('../checkoutV2/checkout/pages/payment/Payment.jsx'))
const Login = React.lazy(() => import('../loginV2/LoginRouter.jsx'))
const LoginStart = React.lazy(() => import('../loginV2/pages/Start.jsx'))
const PasswordLogin = React.lazy(() => import('../loginV2/pages/PasswordLogin.jsx'))
const ForgottenPassword = React.lazy(() => import('../loginV2/pages/ForgottenPassword.jsx'))
const MagicLink = React.lazy(() => import('../loginV2/pages/MagicLink.jsx'))
const RequestPasswordlessToken = React.lazy(() => import('../login/RequestPasswordlessToken.jsx'))
const Signup = React.lazy(() => import('../loginV2/LoginRouter.jsx'))
const PasswordSignUp = React.lazy(() => import('../loginV2/pages/SignUp.jsx'))
const ContainerDropoffQR = React.lazy(() => import('../containerDropoffQR/ContainerDropoffQR.jsx'))
const ScheduledUserActionHandler = React.lazy(() =>
  import('../profile/CompleteScheduledUserAction.jsx')
)

const passwordlessHook = async ({ request }) => {
  const url = new URL(request.url)
  const authToken = url.searchParams.get('authToken')
  if (!authToken) return
  try {
    await Parse.Cloud.run('currentUser')
  } catch (e) {
    if (e.code === Parse.Error.INVALID_SESSION_TOKEN) {
      await Parse.User.logOut()
    }
  }
  try {
    const sessionToken = await Parse.Cloud.run('passwordless_login', { token: authToken })
    if (sessionToken) {
      await Parse.User.become(sessionToken)
      return
    }
  } catch (error) {
    captureException(error)
    return redirect('/login')
  }
}

const RequireAuth = ({ children }) => {
  const location = useLocation()
  if (userTools.currentLoginStatus(Parse.User.current()) === userTools.LOGIN_FULL) return children
  const nextLocation = `${location.pathname}${location.search}`
  return <Navigate to={'/login'} replace state={{ nextLocation }} />
}

const requireAuth = async () => {
  if (userTools.currentLoginStatus(Parse.User.current()) === userTools.LOGIN_FULL) {
    return true
  }
  throw redirect('/login')
}

const RequireFeature = ({ children, requiredFeature }) => {
  if (isFeatureEnabled(requiredFeature)) return children
  return <Navigate to={'/not-enabled'} replace />
}

const requireFeature = (requiredFeature) => {
  if (!isFeatureEnabled(requiredFeature)) throw redirect('/not-enabled')
}

const initializeTrustScore = () => {
  if (showTrustScore) {
    ;({
      // TrustedShops: initializeTrustbadge,
      TrustPilot: initializeMicroTrustScore,
      TrustProfile: initializeTrustProfile
    }[externalReviewService]())
  }
}

let isInitialized = false

const handleInitializations = () => {
  if (isInitialized) return
  ensureLocalStorage()
  initializeParse(config.parse)
  initializeI18n()
  setAbTestFraction()
  initFeatureToggle()
  initializeFacebookAsync(config.facebookAppId)
  initializeTrustScore()
  if (!window.location.href.includes('authToken')) {
    tagManager.initialize({
      gtmId: config.googleTagManagerId,
      dataLayerName: 'gtmDataLayer'
    })
  }
  isInitialized = true
}

export const routes = [
  {
    path: '',
    element: <App />,
    loader: () => true, // Basically so that we know that the unstable_dataStrategy will be used on all routes. Since it runs everything inside the function before any loader.
    shouldRevalidate: () => false,
    errorElement: (
      <Layout>
        <ErrorElement />
      </Layout>
    ),
    children: [
      /* BUYER */
      {
        path: '',
        loader: landingPageLoader,
        errorElement: <FallbackLandingPageContent />,
        lazy: async () => {
          const { default: DefaultComponent } = await import('../landing/Landing.jsx')
          return { Component: DefaultComponent }
        }
      },
      {
        path: 'men',
        loader: landingPageLoader,
        errorElement: <FallbackLandingPageContent />,
        lazy: async () => {
          const { default: DefaultComponent } = await import('../landing/Landing.jsx')
          return { Component: DefaultComponent }
        }
      },
      {
        path: 'women',
        loader: landingPageLoader,
        errorElement: <FallbackLandingPageContent />,
        lazy: async () => {
          const { default: DefaultComponent } = await import('../landing/Landing.jsx')
          return { Component: DefaultComponent }
        }
      },
      {
        path: 'things',
        loader: landingPageLoader,
        errorElement: <FallbackLandingPageContent />,
        lazy: async () => {
          const { default: DefaultComponent } = await import('../landing/Landing.jsx')
          return { Component: DefaultComponent }
        }
      },
      {
        path: 'children',
        loader: landingPageLoader,
        errorElement: <FallbackLandingPageContent />,
        lazy: async () => {
          const { default: DefaultComponent } = await import('../landing/Landing.jsx')
          return { Component: DefaultComponent }
        }
      },
      {
        path: `${HM}`,
        loader: landingPageLoader,
        element: (
          <RequireFeature requiredFeature={FEATURE_HM_COLLAB_BUY}>
            <CollabLanding />
          </RequireFeature>
        )
      },
      {
        path: `${KLARNA}`,
        loader: landingPageLoader,
        element: (
          <RequireFeature requiredFeature={FEATURE_KLARNA_COLLAB_BUY}>
            <CollabLanding />
          </RequireFeature>
        )
      },
      {
        path: `${SNAPCHAT}`,
        loader: landingPageLoader,
        element: (
          <RequireFeature requiredFeature={FEATURE_SNAPCHAT_COLLAB_BUY}>
            <CollabLanding />
          </RequireFeature>
        )
      },
      {
        path: `${FASHIONWEEK}`,
        loader: landingPageLoader,
        element: (
          <RequireFeature requiredFeature={FEATURE_FASHIONWEEK_COLLAB_BUY}>
            <CollabLanding />
          </RequireFeature>
        )
      },
      {
        path: 'item/:itemId/:slug?',
        element: (
          <Suspense fallback={<BuyerPDPSkeleton />}>
            <BuyerPDP />
          </Suspense>
        )
      },
      {
        path: 'search/:category0?/:category1?/:category2?/:category3?/search?',
        element: (
          <Suspense fallback={<SearchSkeleton />}>
            <InstantSearch />
          </Suspense>
        )
      },
      {
        path: 'store/:facet/:value/:slug?',
        element: <Navigate to='search' replace />
      },
      {
        path: 'store/:facet/:value/:slug?/:category0?/:category1?/:category2?/:category3?/search?',
        element: (
          <Suspense fallback={<StoreSkeleton />}>
            <Store />
          </Suspense>
        )
      },
      {
        path: 'checkout',
        element: <Checkout />,
        children: [
          { path: 'cart', element: <Cart /> },
          { path: 'validateEmail', element: <ValidateEmail /> },
          { path: 'address', element: <Address /> },
          { path: 'shipping', element: <Shipping /> },
          { path: 'summary', element: <Summary /> },
          { path: 'payment', element: <Payment /> }
        ]
      },
      { path: 'empty-cart', element: <EmptyCart /> },
      { path: 'advance-payment/:paymentId', element: <AdyenAdvancePayment /> },
      { path: 'hm-resell-return/:token', element: <HmResellReturn /> },
      {
        path: 'newClaim',
        loader: async ({ request }) => {
          await requireAuth()
          const url = new URL(request.url)
          const queryParams = url.searchParams
          const itemId = queryParams.get('itemId')
          const orderId = queryParams.get('orderId')
          const newClaimPageQueryRef = getCreateQueryPreloader()(newClaimPageQuery, {
            variables: { itemId, orderId, ...localeBooleansForGQL() }
          }).toPromise()

          return { newClaimPageQueryRef }
        },
        lazy: async () => {
          const { default: NewClaimPage } = await import('../claims/NewClaimPage.jsx')
          return { Component: NewClaimPage }
        }
      },
      {
        path: 'editClaim',
        loader: async ({ request }) => {
          await requireAuth()
          const url = new URL(request.url)
          const queryParams = url.searchParams
          const itemId = queryParams.get('itemId')
          const editClaimPageQueryRef = getCreateQueryPreloader()(editClaimPageQuery, {
            variables: { itemId, ...localeBooleansForGQL() },
            fetchPolicy: 'network-only'
          }).toPromise()
          return defer({
            editClaimPageQueryRef
          })
        },
        lazy: async () => {
          const { default: EditClaimPage } = await import('../claims/EditClaimPage.jsx')
          return { Component: EditClaimPage }
        }
      },
      {
        path: 'claims/:orderId/:claimId',
        element: (
          <RequireAuth>
            <ClaimView />
          </RequireAuth>
        )
      },
      {
        path: 'claims/:orderId',
        element: (
          <RequireAuth>
            <OrderClaims />
          </RequireAuth>
        )
      },
      {
        path: 'order/:orderId',
        loader: async ({ request }) => {
          const url = new URL(request.url)
          const orderId = url.pathname.split('/').pop()
          await requireAuth()
          return getCreateQueryPreloader()(getOrderOverview, {
            variables: {
              id: orderId,
              isSV: locale === 'sv',
              isDE: locale === 'de',
              isEN: locale === 'en',
              isNL: locale === 'nl',
              isDA: locale === 'da',
              isPL: locale === 'pl',
              isFI: locale === 'fi',
              isFR: locale === 'fr'
            }
          }).toPromise()
        },
        lazy: async () => {
          const { default: Order } = await import('../order/Page.jsx')
          return { Component: Order }
        }
      },
      { path: 'payment/:paymentId', element: <PaymentConfirmation /> },
      {
        path: 'orders',
        loader: async () => {
          await requireAuth()
          return getCreateQueryPreloader()(getOrdersOverview).toPromise()
        },
        lazy: async () => {
          const { Orders } = await import('../orders/Orders.jsx')
          return { Component: Orders }
        }
      },
      { path: 'withinShippingCost', element: <WithinShippingCost /> },
      {
        path: 'gift-card/purchase',
        element: (
          <RequireFeature requiredFeature={FEATURE_CREDITS}>
            <PurchaseGiftCardPage />
          </RequireFeature>
        )
      },
      {
        path: 'gift-card/redeem/succeeded/:giftCardId',
        element: (
          <RequireFeature requiredFeature={FEATURE_CREDITS}>
            <RedeemSucceededPage />
          </RequireFeature>
        )
      },
      {
        path: 'gift-card/redeem/:code?',
        element: (
          <RequireFeature requiredFeature={FEATURE_CREDITS}>
            <RequireAuth>
              <RedeemPage />
            </RequireAuth>
          </RequireFeature>
        )
      },
      { path: 'brands', element: <BrandsPage /> },
      {
        path: 'types',
        element: (
          <RequireFeature requiredFeature={FEATURE_TYPES_PAGE}>
            <TypesPage />
          </RequireFeature>
        )
      },
      {
        path: 'stores/:storeType?',
        loader: () =>
          defer({
            allStores: getCreateQueryPreloader()(getAllStores, {
              variables: { region: region() },
              context: { api: 'sanity' }
            })
          }),
        lazy: async () => {
          const { default: StoresPage } = await import('../categories/stores/StoresPage.jsx')
          return { Component: StoresPage }
        }
      },
      {
        path: 'my-shopping/favorites/:resource?/:id?/search?',
        loader: async ({ params }) => {
          if (params?.resource === 'list' && params.id) {
            return getCreateQueryPreloader()(getFavoritesInList, {
              variables: { listId: params.id }
            }).toPromise()
          }
          return getCreateQueryPreloader()(getAllFavorites, {}).toPromise()
        },
        lazy: async () => {
          const { default: Favorites } = await import('../myShopping/pages/favorites/Favorites.jsx')
          return { Component: Favorites }
        }
      },
      {
        path:
          'my-shopping/previously-in-cart/:category0?/:category1?/:category2?/:category3?/search?',
        loader: async () => {
          await requireAuth()
          const user = Parse.User.current()
          return getCreateQueryPreloader()(getItemsPreviouslyInCart, {
            variables: {
              userId: user.id,
              oldestDate: getOldestDateForPreviouslyInCart()
            }
          }).toPromise()
        },
        lazy: async () => {
          const { default: DefaultComponent } = await import(
            '../myShopping/pages/PreviouslyInCart.jsx'
          )
          return { Component: DefaultComponent }
        }
      },
      {
        path:
          'my-shopping/weekly-recommendations/:category0?/:category1?/:category2?/:category3?/search?',
        loader: async () => {
          await requireAuth()
          const user = Parse.User.current()
          return getCreateQueryPreloader()(getLatestItemRecommendationsForUser, {
            variables: { userId: user.id }
          }).toPromise()
        },
        lazy: async () => {
          const { default: DefaultComponent } = await import(
            '../myShopping/pages/WeeklyRecommendations.jsx'
          )
          return { Component: DefaultComponent }
        }
      },
      {
        path: 'my-shopping/tracked-searches',
        loader: async () => {
          await requireAuth()
          const user = Parse.User.current()
          return getCreateQueryPreloader()(getTrackedSearchesForUser, {
            fetchPolicy: 'cache-and-network',
            variables: { userId: user.id, region: region() }
          }).toPromise()
        },
        lazy: async () => {
          const { default: DefaultComponent } = await import(
            '../myShopping/pages/trackedSearches/TrackedSearches.jsx'
          )
          return { Component: DefaultComponent }
        }
      },
      {
        path: 'my-shopping',
        loader: async () => {
          await requireAuth()
          const user = Parse.User.current()
          const itemRecommendations = getCreateQueryPreloader()(
            getLatestItemRecommendationsForUser,
            { variables: { userId: user.id } }
          )
          const previouslyInCart = getCreateQueryPreloader()(getItemsPreviouslyInCart, {
            variables: {
              userId: user.id,
              oldestDate: getOldestDateForPreviouslyInCart()
            }
          })
          const trackedSearches = getCreateQueryPreloader()(getTrackedSearchesForUser, {
            fetchPolicy: 'cache-and-network',
            variables: { userId: user.id, region: region() }
          })
          return defer({ itemRecommendations, trackedSearches, previouslyInCart })
        },
        element: <BuyerLanding />
      },
      { path: 'upcycled', element: <Upcycle /> },
      {
        path: 'sellpy-labs/search/:category0?/:category1?/:category2?/:category3?',
        element: <CLIPSearch />
      },
      {
        path:
          'sellpy-labs/clip-item-to-image/search?/:category0?/:category1?/:category2?/:category3?',
        element: (
          <RequireAuth>
            <CLIPSearchFromItems />
          </RequireAuth>
        )
      },
      { path: 'unsubscribe/newsletter/:email?', element: <EmailSuppression /> },
      {
        path: 'first/sign-up',
        element: (
          <RequireAuth>
            <LoyaltyProgramSignUp />
          </RequireAuth>
        )
      },
      { path: 'loyalty/sign-up', element: <Navigate to='/first/sign-up' /> },
      {
        path: 'first',
        element: <LoyaltyInfo />
      },

      /* SELLER */
      { path: 'sell', element: <SellerLandingPage /> },
      {
        path: `${FLATTERED}`,
        loader: ({ request }) => {
          const url = new URL(request.url)
          const pathname = url.pathname
          if (pathname === `/${FLATTERED}/`) return redirect(`/${FLATTERED}`)
          return null
        },
        element: (
          <RequireFeature requiredFeature={FEATURE_FLATTERED_COLLAB_SELL}>
            <FlatteredSellerLandingPage />
          </RequireFeature>
        )
      },
      {
        path: 'circle',
        element: (
          <RequireFeature requiredFeature={FEATURE_P2P}>
            <CircleLanding />
          </RequireFeature>
        )
      },
      {
        path: 'sell',
        element: (
          <RequireFeature requiredFeature={FEATURE_SELL}>
            <SellerLandingPage />
          </RequireFeature>
        )
      },
      {
        path: 'sell-signup',
        element: (
          <RequireFeature requiredFeature={FEATURE_SELL_SIGNUP}>
            <SellSignup />
          </RequireFeature>
        )
      },
      {
        path: 'hmbag',
        element: (
          <RequireFeature requiredFeature={FEATURE_HM_COLLAB_SELL}>
            <HmSellerLandingPage />
          </RequireFeature>
        )
      },
      {
        path: 'my-item/:itemId/:slug?',
        loader: async ({ params }) => {
          await requireAuth()
          requireFeature(FEATURE_SELL)

          return getCreateQueryPreloader()(sellerPDPQuery, {
            variables: {
              itemId: params.itemId,
              isSE: locale === 'se',
              isDE: locale === 'de',
              isEN: locale === 'en',
              isNL: locale === 'nl',
              isDA: locale === 'da',
              isPL: locale === 'pl',
              isFI: locale === 'fi',
              isFR: locale === 'fr'
            }
          }).toPromise()
        },
        lazy: async () => {
          return { Component: SellerPDP }
        }
      },
      {
        path: 'my-circle-item/:itemId/:slug?',
        element: (
          <RequireAuth>
            <CircleSellerPDP />
          </RequireAuth>
        )
      },
      {
        path: 'itemEdit/:itemId',
        element: (
          <RequireAuth>
            <ItemEdit />
          </RequireAuth>
        )
      },
      {
        path: 'itemPricingCheckout/:itemId',
        element: (
          <RequireAuth>
            <ItemPricingCheckout />
          </RequireAuth>
        )
      },
      {
        path: 'sales',
        loader: async () => {
          await requireAuth()
          requireFeature(FEATURE_SELL)

          const [
            overviewQueryRef,
            receivedQueryRef,
            activeQueryRef,
            endedQueryRef
          ] = await Promise.all([
            getCreateQueryPreloader()(getSalesOverview, {
              variables: {
                userId: Parse.User.current().id
              }
            }).toPromise(),
            getCreateQueryPreloader()(receivedSalesTabOverview, {
              variables: {
                userId: Parse.User.current().id
              }
            }).toPromise(),
            getCreateQueryPreloader()(activeSalesTabOverview, {
              variables: {
                userId: Parse.User.current().id
              }
            }).toPromise(),
            getCreateQueryPreloader()(endedSalesOverview, {
              variables: {
                userId: Parse.User.current().id
              }
            }).toPromise()
          ])

          return { overviewQueryRef, receivedQueryRef, activeQueryRef, endedQueryRef }
        },
        lazy: async () => {
          const { Sales } = await import('../sales/Sales.jsx')
          return { Component: Sales }
        }
      },
      {
        path: 'order-bag',
        loader: async () => {
          return getCreateQueryPreloader()(getContainerPrice, {
            variables: {
              region: region()
            }
          }).toPromise()
        },
        lazy: async () => {
          const { default: OrderContainer } = await import('../orderBag/Page.jsx')
          return { Component: OrderContainer }
        }
      },

      {
        path: 'orderPickUp',
        element: (
          <RequireFeature requiredFeature={FEATURE_BAG_PICKUP}>
            <RequireAuth>
              <ContainerPickup />
            </RequireAuth>
          </RequireFeature>
        )
      },
      {
        path: 'order-bag-history',
        element: (
          <RequireAuth>
            <ContainerOrderHistory />
          </RequireAuth>
        )
      },
      {
        path: 'order-bag-history/:containerOrderId',
        element: (
          <RequireAuth>
            <ContainerOrder />
          </RequireAuth>
        )
      },
      {
        path: 'rateSale/:bagOrderId',
        loader: async ({ params }) => {
          const saleId = params.bagOrderId
          return redirect(`/sale/${saleId}`)
        }
      },
      {
        path: 'balance',
        element: (
          <RequireFeature requiredFeature={FEATURE_SELL}>
            <RequireAuth>
              <Balance />
            </RequireAuth>
          </RequireFeature>
        )
      },
      {
        path: 'withdraw',
        loader: async () => {
          await requireAuth()
          requireFeature(FEATURE_SELL)
          const { AWAITING_VERIFICATION, ORDERED, ...otherStatuses } = payout.PAYOUT_STATUS
          const [
            ongoingPayoutsQueryRef,
            pastPayoutsQueryRef,
            currentAutomaticPayoutConfigQueryRef
          ] = await Promise.all([
            getCreateQueryPreloader()(withdraw, {
              variables: {
                statuses: [AWAITING_VERIFICATION, ORDERED]
              }
            }).toPromise(),
            getCreateQueryPreloader()(withdraw, {
              variables: {
                statuses: Object.values(otherStatuses)
              }
            }).toPromise(),
            getCreateQueryPreloader()(automaticPayoutConfig, {
              variables: {
                currency: marketplace.CURRENCY[region()]
              }
            }).toPromise()
          ])

          return {
            ongoingPayoutsQueryRef,
            pastPayoutsQueryRef,
            currentAutomaticPayoutConfigQueryRef
          }
        },
        lazy: async () => {
          const { Withdraw } = await import('../withdraw/Withdraw.jsx')
          return { Component: Withdraw }
        }
      },
      { path: 'my-balance', element: <Navigate to='/balance' /> },
      { path: 'requestPayout', element: <Navigate to='/balance' /> },
      {
        path: 'requestCharityPayout',
        element: (
          <RequireFeature requiredFeature={FEATURE_SELL}>
            <RequireAuth>
              <CharityPayout />
            </RequireAuth>
          </RequireFeature>
        )
      },
      {
        path: 'requestBankPayout',
        element: (
          <RequireFeature requiredFeature={FEATURE_SELL}>
            <RequireAuth>
              <BankPayout />
            </RequireAuth>
          </RequireFeature>
        )
      },
      {
        path: 'charityPayoutComplete/:payoutId',
        element: (
          <RequireFeature requiredFeature={FEATURE_SELL}>
            <RequireAuth>
              <CharityPayoutComplete />
            </RequireAuth>
          </RequireFeature>
        )
      },
      {
        path: 'bankPayoutComplete/:payoutId',
        element: (
          <RequireFeature requiredFeature={FEATURE_SELL}>
            <RequireAuth>
              <BankPayoutComplete />
            </RequireAuth>
          </RequireFeature>
        )
      },
      {
        path: 'bankAccountVerificationComplete/:bankAccountId',
        loader: async ({ params }) => {
          const bankAccountId = params.bankAccountId
          await requireAuth()
          return getCreateQueryPreloader()(getBankAccount, {
            variables: {
              id: bankAccountId
            }
          }).toPromise()
        },
        lazy: async () => {
          const { default: BankAccountVerification } = await import(
            '../bankAccount/BankAccountVerificationComplete.jsx'
          )
          return { Component: BankAccountVerification }
        }
      },
      {
        path: 'payoutVerificationInfo',
        loader: async () => {
          await requireAuth()
          requireFeature(FEATURE_SELL)
          return getCreateQueryPreloader()(getUnverifiedPayOutAndBankAccount, {
            variables: {
              status: payout.PAYOUT_STATUS.AWAITING_VERIFICATION
            }
          }).toPromise()
        },
        lazy: async () => {
          const { default: PayoutVerificationInfo } = await import(
            '../payOuts/bank/PayoutVerificationInfo.jsx'
          )
          return { Component: PayoutVerificationInfo }
        }
      },
      {
        path: 'verify-payout/:token',
        loader: async ({ params }) => {
          return redirect(`/verify-bank-account/${params.token}`)
        }
      },
      {
        path: 'verify-bank-account/:token',
        element: (
          <RequireFeature requiredFeature={FEATURE_SELL}>
            <VerifyBankAccount />
          </RequireFeature>
        )
      },
      {
        path: 'payout-setup',
        element: (
          <RequireFeature requiredFeature={FEATURE_SELL}>
            <RequireAuth>
              <Setup />
            </RequireAuth>
          </RequireFeature>
        )
      },
      {
        path: 'payout-setup/bankAccount',
        element: (
          <RequireFeature requiredFeature={FEATURE_SELL}>
            <RequireAuth>
              <BankAccountSetup />
            </RequireAuth>
          </RequireFeature>
        )
      },
      {
        path: 'payout-setup/charity',
        element: (
          <RequireFeature requiredFeature={FEATURE_SELL}>
            <RequireAuth>
              <CharityAccountSetup />
            </RequireAuth>
          </RequireFeature>
        )
      },
      {
        path: 'payout-setup/confirmation',
        loader: async () => {
          await requireAuth()
          requireFeature(FEATURE_SELL)
          return getCreateQueryPreloader()(automaticPayoutConfig, {
            variables: {
              currency: marketplace.CURRENCY[region()]
            }
          }).toPromise()
        },
        element: (
          <RequireFeature requiredFeature={FEATURE_SELL}>
            <RequireAuth>
              <AutomaticPayoutConfirmation />
            </RequireAuth>
          </RequireFeature>
        )
      },
      {
        path: 'bagsubscription/manage',
        element: (
          <RequireFeature requiredFeature={FEATURE_SELL}>
            <ContainerSubscriptionPage />
          </RequireFeature>
        )
      },
      {
        path: 'howItWorks',
        element: (
          <RequireFeature requiredFeature={FEATURE_SELL}>
            <HowItWorks />
          </RequireFeature>
        )
      },
      {
        path: 'pricingInfo',
        element: (
          <RequireFeature requiredFeature={FEATURE_SELL}>
            <PricingInfo />
          </RequireFeature>
        )
      },
      {
        path: 'createSellerReturn/:itemId',
        element: (
          <RequireFeature requiredFeature={FEATURE_SELL}>
            <RequireAuth>
              <CreateSellerReturn />
            </RequireAuth>
          </RequireFeature>
        )
      },
      {
        path: 'credit/balance',
        element: (
          <RequireFeature requiredFeature={FEATURE_CREDITS}>
            <RequireAuth>
              <CreditPage />
            </RequireAuth>
          </RequireFeature>
        )
      },
      {
        path: 'unsellableAction/:containerId',
        element: <UnsellableAction />
      },
      {
        path: 'fulfill/order/:orderId',
        element: (
          <RequireAuth>
            <FulfillP2POrder />
          </RequireAuth>
        )
      },
      {
        path: 'user/orders/:orderId',
        element: (
          <RequireAuth>
            <OrderSearch />
          </RequireAuth>
        )
      },
      {
        path: 'user/orders',
        element: (
          <RequireAuth>
            <OrderSearch />
          </RequireAuth>
        )
      },
      {
        path: 'user/sales/:saleId',
        loader: async ({ request, params }) => {
          const url = new URL(request.url)
          const searchParams = url.searchParams
          const saleId = params.saleId

          /*
           *  The previous iteration filtered sales based on status, since we are re-using the
           *  same route for the new sales control, we can't accept statuses as saleIds
           */
          const DEPRECATED_SALE_IDS = Object.values(ITEM_STATUS)
          if (DEPRECATED_SALE_IDS.includes(saleId)) return redirect(`/sales`)

          const searchParamSaleId = searchParams.get('filters[0][values][0]')
          if (searchParamSaleId || searchParamSaleId?.length === 10)
            return redirect(`/sale/${searchParamSaleId}`)
          if (saleId.length === 10) return redirect(`/sale/${saleId}`)
          return redirect(`/sales`)
        }
      },
      {
        path: 'user/sales',
        loader: async () => {
          return redirect(`/sales`)
        }
      },
      {
        path: 'user/circle/:category?/:subCategory?',
        element: (
          <RequireFeature requiredFeature={FEATURE_P2P}>
            <RequireAuth>
              <CircleSearch />
            </RequireAuth>
          </RequireFeature>
        )
      },
      {
        path: 'sale/:saleId',
        loader: async (request) => {
          await requireAuth()
          return salesControlLoader(request)
        },
        lazy: async () => {
          const { default: SalesControl } = await import('../myItems/salesControl/SalesControl.jsx')
          return { Component: SalesControl }
        }
      },
      {
        path: 'dropoffbag',
        element: (
          <RequireFeature requiredFeature={FEATURE_SELL}>
            <RequireAuth>
              <ContainerDropoffQR />
            </RequireAuth>
          </RequireFeature>
        )
      },
      {
        path: 'hm-pickup',
        element: (
          <RequireFeature requiredFeature={FEATURE_HM_COLLAB_SELL}>
            <RequireAuth>
              <CollabContainerPickup />
            </RequireAuth>
          </RequireFeature>
        )
      },

      /* MISCELLANOUS */
      {
        path: 'login',
        element: <Login />,
        children: [
          { path: '', element: <LoginStart /> },
          { path: 'password', element: <PasswordLogin /> },
          { path: 'forgottenpassword', element: <ForgottenPassword /> },
          { path: 'link', element: <MagicLink /> },
          { path: 'request-token', element: <RequestPasswordlessToken /> }
        ]
      },
      {
        path: 'signup',
        element: <Signup />,
        children: [
          { path: '', element: <Navigate to={'/login'} replace /> },
          { path: 'password', element: <PasswordSignUp /> }
        ]
      },
      { path: 'recoverPassword', element: <Navigate to='/passwordReset' /> },
      { path: 'passwordReset', element: <PasswordReset /> },
      { path: 'deprecated-route', element: <DeprecatedRoute /> },
      {
        path: 'customer-impersonation',
        element: (
          <RequireAuth>
            <CustomerImpersonation />
          </RequireAuth>
        )
      },
      {
        path: 'home/*',
        element: (
          <RequireAuth>
            <Home />
          </RequireAuth>
        )
      },
      {
        path: 'referrals',
        element: (
          <RequireFeature requiredFeature={FEATURE_REFERRALS}>
            <RequireAuth>
              <ReferralsPage />
            </RequireAuth>
          </RequireFeature>
        )
      },
      {
        path: 'invite/:referralCode',
        element: (
          <RequireFeature requiredFeature={FEATURE_REFERRALS}>
            <RequireAuth>
              <Invited />
            </RequireAuth>
          </RequireFeature>
        )
      },
      { path: 'business', element: <BusinessLandingPage /> },
      { path: 'press', element: <PressLandingPage /> },
      { path: 'press/images/:imageCategory', element: <PressImages /> },
      { path: 'press/news/:release', element: <PressReleasePage /> },
      {
        path: 'profile/password',
        element: (
          <RequireAuth>
            <PasswordPage />
          </RequireAuth>
        )
      },
      {
        path: 'profile/scheduled-user-action/:action/:event/:token',
        element: <ScheduledUserActionHandler />
      },
      {
        path: 'profile',
        element: (
          <RequireAuth>
            <Profile />
          </RequireAuth>
        )
      },
      { path: 'sustainability', element: <Sustainability /> },
      { path: 'support', element: <Support /> },
      { path: 'not-enabled', element: <NotEnabledFeature /> },
      { path: 'about', element: <About /> },
      {
        path: 'statement',
        element: (
          <RequireFeature requiredFeature={FEATURE_STATEMENT}>
            <StatementLanding />
          </RequireFeature>
        )
      },
      {
        path: 'feature-toggle',
        element: (
          <RequireAuth>
            <FeatureToggle />
          </RequireAuth>
        )
      },
      { path: 'newsletter', element: <Newsletter /> },
      { path: 'open-source-licenses', element: <Licenses /> },
      {
        path: 'app-store',
        element: (
          <>
            {isMobile.apple.phone ? (
              <Navigate
                to={`https://apps.apple.com/${locale}/app/sellpy-shop-second-hand/id1594599102`}
              />
            ) : isMobile.android.phone ? (
              <Navigate to='https://play.google.com/store/apps/details?id=com.sellpy.sellpy' />
            ) : (
              <Navigate to='/' />
            )}
          </>
        )
      },

      /* REDIRECTS */
      { path: 'market', element: <Navigate to='/' /> },
      { path: 'newPickUpOrder', element: <Navigate to='/orderPickUp' /> },
      { path: 'foretag', element: <Navigate to='/business' /> },
      {
        path: 'studiovintage',
        element: <Navigate to='/store/featuredIn/yvr9j8ECBU/hm-studio-store' />
      },
      { path: 'saleBalance/:saleId', element: <Navigate to='/sales' /> },
      { path: 'myBags', element: <Navigate to='/sales' /> },
      { path: 'newBagOrder', element: <Navigate to='/order-bag' /> },
      { path: 'orderBag', element: <Navigate to='/order-bag' /> },
      { path: 'order-bag/confirmation/:bagId', element: <Navigate to={'/deprecated-route'} /> },
      { path: 'whatWeSell', element: <Navigate to='/sell' /> },
      {
        path: 'cart',
        // Redirect including search params
        loader: ({ request }) => {
          const url = new URL(request.url)
          url.pathname = '/checkout/cart'
          return redirect(url.toString())
        },
        element: <Navigate to='/checkout/cart' />
      },
      {
        path: 'data-policy',
        Component: () => {
          window.location.href = regionFunctions.dataPolicyLink(process.env.REGION)
          return null
        }
      },
      {
        path: 'agreements/purchase_policy',
        Component: () => {
          window.location.href = regionFunctions.purchasePolicyLink(process.env.REGION)
          return null
        }
      },
      {
        path: 'agreements/purchase_policy.html',
        Component: () => {
          window.location.href = regionFunctions.purchasePolicyLink(process.env.REGION)
          return null
        }
      },
      {
        path: 'agreements/cookie_policy',
        Component: () => {
          window.location.href = regionFunctions.cookiePolicyLink(process.env.REGION)
          return null
        }
      },
      {
        path: 'agreements/cookie_policy.html',
        Component: () => {
          window.location.href = regionFunctions.cookiePolicyLink(process.env.REGION)
          return null
        }
      },
      {
        path: 'agreements/data_policy',
        Component: () => {
          window.location.href = regionFunctions.dataPolicyLink(process.env.REGION)
          return null
        }
      },
      {
        path: 'agreements/data_policy.html',
        Component: () => {
          window.location.href = regionFunctions.dataPolicyLink(process.env.REGION)
          return null
        }
      },
      {
        path: 'agreements/loyalty_policy',
        Component: () => {
          window.location.href = regionFunctions.loyaltyPolicyLink(process.env.REGION)
          return null
        }
      },
      {
        path: 'agreements/loyalty_policy.html',
        Component: () => {
          window.location.href = regionFunctions.loyaltyPolicyLink(process.env.REGION)
          return null
        }
      },
      {
        path: 'agreements/selling_policy',
        Component: () => {
          window.location.href = regionFunctions.sellingPolicyLink(process.env.REGION)
          return null
        }
      },
      {
        path: 'agreements/selling_policy.html',
        Component: () => {
          window.location.href = regionFunctions.sellingPolicyLink(process.env.REGION)
          return null
        }
      },
      { path: 'sendinbag', element: <Navigate to='/dropoffbag' /> },
      { path: 'saleItems/:bagId', element: <Navigate to='/user/sales' /> },
      { path: 'user/items/sales', element: <Navigate to='/user/sales' /> },
      { path: 'user/items/orders', element: <Navigate to='/user/orders' /> },
      { path: 'user/items/orders', element: <Navigate to='/user/orders' /> },
      { path: 'wishlist', element: <Navigate to='/my-shopping/favorites' /> },
      { path: 'favorites', element: <Navigate to='/my-shopping/favorites' /> },
      {
        path: 'favorites/weekly-recommendations',
        element: <Navigate to='/my-shopping/weekly-recommendations' />
      },
      {
        path: 'favorites/previously-in-cart',
        element: <Navigate to='/my-shopping/previously-in-cart' />
      },
      {
        path: 'favorites/user/:userId/weekly-recommendations',
        element: <Navigate to='/my-shopping/weekly-recommendations' />
      },
      { path: 'trackedSearches', element: <Navigate to='/my-shopping/tracked-searches' /> },
      { path: 'favorites/user/:userId', element: <Navigate to='/my-shopping/favorites' /> },
      { path: 'upcycle', element: <Navigate to='/upcycled' /> },
      { path: 'loyalty', element: <Navigate to='/first' /> },
      { path: 'app-store', element: <Navigate to='/root' /> },
      /* CATCH-ALL */
      { path: '*', element: <NotFound /> }
    ]
  }
]

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter)
export const router =
  process.env.NODE_ENV === 'test' || process.env.APP_PAYMENT === true
    ? null
    : sentryCreateBrowserRouter(routes, {
        // This function allows us to run code before the route is resolved
        // Beware that this function might change in newer releases of react-router so we might need to keep track of that
        async unstable_dataStrategy({ request, matches }) {
          handleInitializations()
          await passwordlessHook({ request })
          return Promise.all(matches.map(async (match) => match.resolve()))
        }
      })
